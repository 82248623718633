<route>
{
  "meta": {
    "auth": "userList"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">用户ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入用户ID"
            v-model="searchValue.memberId"
            clearable
            @keyup.enter.native="getList"
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">微信昵称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入微信昵称"
            v-model.trim="searchValue.nickname"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">用户类型</span>
          <el-select class="searchInput" v-model="searchValue.type" placeholder="请选择用户类型">
            <el-option label="全部" value="">全部 </el-option>
            <el-option label="未购买" :value="0">未购买 </el-option>
            <el-option label="购买过" :value="1">购买过 </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">手机号</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入手机号"
            v-model.trim="searchValue.purePhoneNumber"
            clearable
          >
          </el-input>
        </el-col>

        <el-col :span="12">
          <span class="searchText">注册时间</span>
          <el-date-picker
            v-model="data"
            type="datetimerange"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="query">
        <el-col :span="8" v-if="$store.getters['user/isAllArea']">
          <span class="searchText">授权地区</span>
          <el-select
            class="searchInput"
            placeholder="请选择地区"
            @change="getList"
            v-model="searchValue.authorizedArea"
          >
            <el-option label="全部" :value="undefined"></el-option>
            <el-option label="无" :value="0"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8" v-if="$store.getters['user/isAllArea']">
          <span class="searchText">活跃地区</span>
          <el-select class="searchInput" placeholder="请选择地区" @change="getList" v-model="searchValue.activeArea">
            <el-option label="全部" :value="undefined"></el-option>
            <el-option label="无" :value="0"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="info" label="微信头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="ID"> </el-table-column>
        <el-table-column align="center" prop="purePhoneNumber" label="手机号"> </el-table-column>
        <el-table-column align="center" prop="wxNickname" label="微信名"> </el-table-column>
        <el-table-column align="center" prop="" label="账号状态">
          <template slot-scope="scope">
            {{ scope.row.isBlacklisted ? '被拉黑' : '正常' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="用户类型">
          <template slot-scope="scope">
            {{ scope.row.isBuy ? '已购买' : '未购买' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="授权地区">
          <template slot-scope="scope">
            <div v-if="scope.row.authorizedArea == 0">无</div>
            <div v-else>{{ scope.row.authorizedArea | formatArea }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="活跃地区">
          <template slot-scope="scope">
            <div v-if="scope.row.activeArea == 0">无</div>
            <div v-else>{{ scope.row.activeArea | formatArea }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small"> 详情</el-button>
            <el-button
              v-auth="'userBlock'"
              v-if="!scope.row.isBlacklisted"
              @click="pullBlack(scope.row)"
              type="text"
              size="small"
            >
              拉黑</el-button
            >
            <el-button v-auth="'userBlock'" v-else @click="pullBlack(scope.row)" type="text" size="small">
              取消拉黑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="blockUser.show" width="30%">
      <span class="assist">{{ blockUser.blockContext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="blockUser.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmBlock">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      data: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      searchValue: {
        authorizedArea: undefined,
        activeArea: undefined,
        purePhoneNumber: '',
        endDate: '',
        memberId: '',
        nickname: '',
        type: '',
        startDate: ''
      },
      stateOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '授权已购买'
        },
        {
          value: '1',
          label: '授权未购买'
        },
        {
          value: '2',
          label: '未授权'
        }
      ],
      list: [],
      blockUser: { url: '', tip: '', show: false, blockContext: '', id: '' }
    }
  },

  methods: {
    // 请求后端获取用户列表
    getList(val) {
      this.loading = true
      this.searchValue.endDate = undefined
      this.searchValue.startDate = undefined
      if (this.data) {
        this.searchValue.endDate = this.data[1]
        this.searchValue.startDate = this.data[0]
      }
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          ...this.searchValue
        }
      }

      this.$api.user
        .user('userList', params)
        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 点击拉黑或者反拉黑按钮
    pullBlack(row) {
      if (row.isBlacklisted) {
        this.blockUser.blockContext = '是否取消拉黑该用户'
        this.blockUser.url = 'userCancelBlock'
        this.blockUser.tip = '取消拉黑成功'
      } else {
        this.blockUser.blockContext = '是否拉黑该用户'
        this.blockUser.url = 'userPullBlack'
        this.blockUser.tip = '拉黑成功'
      }
      this.blockUser.show = true
      this.blockUser.id = row.id
    },
    resetSearch() {
      this.searchValue = {
        endDate: '',
        memberId: '',
        nickname: '',
        type: '',
        purePhoneNumber: '',
        authorizedArea: undefined,
        activeArea: undefined,
        startDate: '',
        page: 1,
        size: 10
      }
      this.data = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.getList()
    },
    // 确认拉黑用户
    confirmBlock() {
      this.$api.user
        .user(
          this.blockUser.url,
          {
            memberId: this.blockUser.id + ''
          },
          {
            headers: { 'Sys-User-Id': 1 }
          }
        )
        .then(res => {
          this.getList()
          this.blockUser.show = false
          this.$message.success(this.blockUser.tip)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 查看用户详情，点击跳转到详情
    check(row) {
      this.$router.push({
        path: '/user/particulars',
        query: {
          id: row.id
        }
      })
    }
  },
  filters: {
    userStatus(val) {
      switch (val) {
        case 2:
          return '授权已购买'
          break
        case 3:
          return '授权未购买'
          break
        default:
          return '未授权'
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.platform-data {
  display: flex;
}

.searchContainer .searchInput {
  width: 180px;
}

.searchContainer .searchInputRange {
  width: 80px;
}
.query {
  margin-top: 20px;
}
.avatar {
  width: 50px;
  height: 50px;
}
</style>
